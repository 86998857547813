<template>
    <AdminWrapper>
        <template v-slot:child>
            <template v-if="$store.state.Admin.loadingState"><Spinner /></template>
            <!--Customer Transactions Detail -->
            <ChangePlan :accountID="accountDetails._id" :closeModal="() => toggleChangePlan()" />
            <div class="w-90 center pt4-l pt6 font-w1">
                <router-link :to="{ name: 'AdminAccount' }"> &lt; Account</router-link>
            </div>
            <div class="w-90 center pt2">
                <div class="flex flex-wrap justify-between items-center pt2 pb4 box-border-bottom">
                    <div>
                        <div class="pb3 pb0-l f3 b">{{ accountDetails.name }} Details</div>
                    </div>
                    <div class="flex" v-if="$store.state.Admin.adminUserData.userType === 'admin'">
                        <div>
                            <button class="btn1" @click.prevent="cloneBusiness()">Clone Business</button>
                        </div>

                        <div class="pl3" v-if="accountDetails.isDeactivated === true">
                            <button
                                :disabled="$store.state.Admin.loadingState"
                                class="btn1"
                                @click.prevent="deactivateAndReactivateBiz()"
                            >
                                Reactivate User
                            </button>
                        </div>

                        <div class="pl3" v-else>
                            <button
                                :disabled="$store.state.Admin.loadingState"
                                class="btn1"
                                @click.prevent="deactivateAndReactivateBiz()"
                            >
                                Deactivate User
                            </button>
                        </div>
                        <button
                            style="background-color: white; border: 1px solid #132C8C; color: #132C8C;"
                            class="dropdown-togglex ml2"
                            role="button"
                            id="dropdownMenuLink"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            More <font-awesome-icon icon="fa-solid fa-caret-down" class="fa-2x" />
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <li>
                                <router-link :to="'#'" @click.prevent="sendTestInvoiceReminder">Send Invoice Reminder</router-link>
                            </li>
                            <li>
                                <router-link :to="'#'" @click.prevent="sendTestPnlReport">Send PNL Report</router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'AdminAccountActivities', params: { id: accountDetails._id } }">View Activity</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!--Customer Transactions Detail -->

            <!-- customer details, full name, phone-->
            <div class="w-90 center flex flex-wrap pt4">
                <div class="w-25-l w-100 box-border pv4 tc">
                    <img :src="require('@/assets/images/wood-table.png')" alt="" />
                    <div class="pt3 b" style="color: #132c8c">
                        {{ accountDetails?.name }}
                    </div>
                    <router-link
                        v-if="$store.state.Admin.adminUserData.userType === 'admin'"
                        :to="{ name: 'AdminBusinessUpdate', params: { _id: accountDetails._id } }"
                        class="pt3"
                    >
                        <button class="btn2">Edit Profile</button>
                    </router-link>
                    <div v-if="$store.state.Admin.adminUserData.userType === 'admin'" class="mt2" @click.prevent="sendWeeklyReport">
                        <button class="btn2">Send Weekly Report</button>
                    </div>
                </div>

                <div class="w-25-l w-100 box-border pa4">
                    <div class="pv2">
                        <div class="pb2" style="color: #55596ebf">Business name</div>
                        <div>{{ accountDetails?.name }}</div>
                    </div>
                    <div class="pv2">
                        <div class="pb2" style="color: #55596ebf">Email</div>
                        <div>{{ accountDetails?.orgEmail }}</div>
                    </div>
                    <div class="pv2">
                        <div class="pb2" style="color: #55596ebf">Phone number</div>
                        <div>{{ accountDetails?.phoneNumber }}</div>
                    </div>
                    <div class="pv2">
                        <div class="pb2" style="color: #55596ebf">
                            Primary business owner name
                        </div>
                        <div>{{ accountDetails?.owner?.name }}</div>
                    </div>
                    <div class="pv2">
                        <div class="pb2" style="color: #55596ebf">
                            Primary business owner email
                        </div>
                        <div>{{ accountDetails?.owner?.email }}</div>
                    </div>
                </div>

                <div class="w-25-l w-100 box-border pa4">
                    <div class="pv2">
                        <div class="pb2" style="color: #55596ebf">Type of business</div>
                        <div>{{ accountDetails?.typeOfBusiness }}</div>
                    </div>
                    <div class="pv2">
                        <div class="pb2" style="color: #55596ebf">Address</div>
                        <div>{{ accountDetails?.address }}</div>
                    </div>
                    <div class="pv2">
                        <div class="pb2" style="color: #55596ebf">Country</div>
                        <div>{{ accountDetails?.country }}</div>
                    </div>
                    <div class="pv2">
                        <div class="pb2" style="color: #55596ebf">Type of business</div>
                        <div>{{ accountDetails?.typeOfBusiness }}</div>
                    </div>
                    <div class="pv2">
                        <div class="pb2" style="color: #55596ebf">Business model</div>
                        <div>{{ accountDetails?.business }}</div>
                    </div>
                </div>

                <div class="w-25-l w-100 box-border pa4">
                    <div class="pv2">
                        <div class="pb2" style="color: #55596ebf">
                            How did you hear about us
                        </div>
                        <div class="pb2">{{ accountDetails?.lead || '-' }}</div>
                    </div>
                    <div class="pv2">
                        <div class="pb2" style="color: #55596ebf">Created date</div>
                        <div>{{ formatDate(accountDetails?.createdAt) }}</div>
                    </div>
                    <div class="pv2">
                        <div class="pb2" style="color: #55596ebf">Signup Date</div>
                        <div>{{ formatDate(accountDetails?.date) }}</div>
                    </div>
                    <div class="pv2">
                        <div class="pb2" style="color: #55596ebf">Last transaction date</div>
                        <div>-</div>
                    </div>
                    <div class="pv2">
                        <div class="pb2" style="color: #55596ebf">Top Products</div>
                        <span v-for="prod in accountDetails.topSellingProducts" :key="prod">
                            <span>{{ prod.name }}, </span>
                        </span>
                    </div>
                    <div class="pv2">
                        <div class="pb2" style="color: #55596ebf">Top Customers</div>
                        <span v-for="cust in accountDetails.topCustomers" :key="cust">
                            <span>{{ cust.name }}, </span>
                        </span>
                    </div>
                </div>
            </div>

            <!-- product description -->
            <div class="w-90 center pt4">
                <div class="pa4 box-border">
                    <div class="pb2 b fs13">Business Bio</div>
                    <div class="lh-copy" style="font-size: 14px">
                        Train hard. Stay dry. This soccer jacket is made of soft, sweat-wicking fabric that keeps you moving on
                        the practice field. Stretch panels at the elbows and sides give you a full range of motion as you work.
                    </div>
                </div>
            </div>

            <!-- Cards  -->
            <div class="w-90 center flex flex-wrap pt4">
                <!-- transaction -->
                <div class="pv4 pl3-l pl2 w-20-l w-50 box-border1 adj-text">
                    <div class="flex items-center">
                        <img :src="require('@/assets/images/trans-admin.svg')" alt="" />
                        <p class="pl2 font-w1">Transactions (count & value)</p>
                    </div>
                    <div class="flex justify-between">
                        <div>
                            <div class="pt3">
                                <div>Value</div>
                            </div>
                            <div style="color: #132c8c">
                                <h3 class="mb0">{{ formatAmount($store.state.Admin?.getBusinessTransaction.value) }}</h3>
                            </div>
                        </div>
                        <div class="pr3-l pr2 pl1">
                            <div class="pt3">
                                <div>Count</div>
                            </div>
                            <div style="color: #132c8c">
                                <h3 class="mb0">{{ $store.state.Admin?.getBusinessTransaction.count }}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Transaction-->
                <!-- users by plan-->
                <div class="pv4 pl3-l pl2 w-20-l w-50 box-border1 adj-text">
                    <div class="flex items-center">
                        <img :src="require('@/assets/images/users-plan-admin.svg')" alt="" />
                        <p class="pl2 font-w1">Invoice</p>
                    </div>
                    <div class="flex justify-between">
                        <div>
                            <div class="pt3">
                                <div>Value</div>
                            </div>
                            <div style="color: #132c8c">
                                <h3 class="mb0">{{ formatAmount($store.state.Admin?.getBusinessInvoice.value) }}</h3>
                            </div>
                        </div>
                        <div class="pr3-l pr2 pl1">
                            <div class="pt3">
                                <div>Count</div>
                            </div>
                            <div style="color: #132c8c">
                                <h3 class="mb0">{{ $store.state.Admin?.getBusinessInvoice.count }}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- users by plan -->
                <!-- no of staff-->
                <div class="pv4 pl3-l pl2 w-20-l w-50 box-border1 adj-text">
                    <div class="flex items-center">
                        <img :src="require('@/assets/images/users-svg.svg')" alt="" />
                        <p class="pl2 font-w1">Products</p>
                    </div>
                    <div class="pt3">
                        <div>{{ $store.state.Admin?.getBusinessProduct.today || '0' }} today</div>
                    </div>
                    <div style="color: #132C8C;">
                        <h3 class="mb0">{{ $store.state.Admin?.getBusinessProduct.count }}</h3>
                    </div>
                </div>
                <!-- no of staff -->
                <!-- Entities-->
                <div class="pv4 pl3-l pl2 w-20-l w-50 box-border1 adj-text">
                    <div class="flex items-center">
                        <img :src="require('@/assets/images/entities-admin.png')" alt="" />
                        <p class="pl2 font-w1">Entities</p>
                    </div>
                    <div class="pt3">
                        <div>{{ $store.state.Admin?.getBusinessEnttities.today || '0' }} today</div>
                    </div>
                    <div style="color: #132C8C;">
                        <h3 class="mb0">{{ $store.state.Admin?.getBusinessEnttities.count }}</h3>
                    </div>
                </div>
                <!-- Entities -->
                <!-- invoice-->
                <div class="pv4 pl3-l pl2 w-20-l w-50 box-border1 adj-text">
                    <div class="flex items-center">
                        <img :src="require('@/assets/images/entities-admin.png')" alt="" />
                        <p class="pl2 font-w1">Services</p>
                    </div>
                    <div class="pt3">
                        <div>{{ $store.state.Admin?.getBusinessService.today }} today</div>
                    </div>
                    <div style="color: #132C8C;">
                        <h3 class="mb0">{{ $store.state.Admin?.getBusinessService.count }}</h3>
                    </div>
                </div>
                <div class="pv4 pl3-l pl2 w-20-l w-50 box-border1 adj-text">
                    <div class="flex items-center">
                        <img :src="require('@/assets/images/entities-admin.png')" alt="" />
                        <p class="pl2 font-w1">Current Plan</p>
                    </div>
                    <div class="pt3">
                        <div>Plan</div>
                    </div>
                    <div style="color: #132c8c">
                        <h3 class="mb0">-</h3>
                    </div>
                </div>

                <!-- <div class="pv4 pl3-l pl2 w-20-l w-50 box-border1 adj-text">
                    <div class="flex items-center">
                        <img :src="require('@/assets/images/invoice-admin.svg')" alt="" />
                        <p class="pl2 font-w1">Services</p>
                    </div>
                    <div class="flex justify-between">
                        <div>
                            <div class="pt3">
                                <div>Re-order</div>
                            </div>
                            <div style="color: #132c8c">
                                <h3 class="mb0">40</h3>
                            </div>
                        </div>
                        <div class="pr3-l pr2 pl1">
                            <div class="pt3">
                                <div>In-stock</div>
                            </div>
                            <div style="color: #132c8c">
                                <h3 class="mb0">50</h3>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- invoice -->
            </div>

            <!-- Report  -->
            <div class="w-90 center mt5 f4 font-w2">View Reports</div>
            <div class="flex flex-wrap justify-between w-90 center mt3 mb4">
                <div class="admin-report box-border pa3 mb0-l mb3">
                    <img :src="require('@/assets/images/money-box.svg')" class="pb2" alt="" />
                    <div class="b pb2">Profit & Loss</div>
                    <router-link :to="{ name: 'AdminBusinessReport', params: { id: $route.params.id } }"
                        ><div class="font-w1" style="color: #132c8c">
                            View report &#62;
                        </div></router-link
                    >
                </div>
                <div class="admin-report box-border pa3 mb0-l mb3">
                    <img :src="require('@/assets/images/money-box.svg')" class="pb2" alt="" />
                    <div class="b pb2">Balance Sheet</div>
                    <router-link :to="{ name: 'AdminBalanceSheetReport', params: { id: $route.params.id } }"
                        ><div class="font-w1" style="color: #132c8c">
                            View report &#62;
                        </div></router-link
                    >
                </div>
                <div class="admin-report box-border pa3 mb0-l mb3">
                    <img :src="require('@/assets/images/money-box.svg')" class="pb2" alt="" />
                    <div class="b pb2">Sales</div>
                    <router-link :to="{ name: 'AdminSalesReport', params: { id: $route.params.id } }"
                        ><div class="font-w1" style="color: #132c8c">
                            View report &#62;
                        </div></router-link
                    >
                </div>
                <div class="admin-report box-border pa3 mb0-l mb3">
                    <img :src="require('@/assets/images/money-box.svg')" class="pb2" alt="" />
                    <div class="b pb2">Cashflow</div>
                    <div>Coming soon...</div>
                    <!-- <a href="#"
                        ><div class="font-w1" style="color: #132c8c">
                            View report &#62;
                        </div></a
                    > -->
                </div>
                <div class="admin-report box-border pa3 mb0-l mb3">
                    <img :src="require('@/assets/images/money-box.svg')" class="pb2" alt="" />
                    <div class="b pb2">Inventory</div>
                    <router-link :to="{ name: 'AdminInventorySheetReport', params: { id: $route.params.id } }"
                        ><div class="font-w1" style="color: #132c8c">
                            View report &#62;
                        </div></router-link
                    >
                </div>
                <div class="admin-report box-border pa3 mb0-l mb3">
                    <img :src="require('@/assets/images/money-box.svg')" class="pb2" alt="" />
                    <div class="b pb2">Expense</div>
                    <router-link :to="{ name: 'AdminExpenseSheetReport', params: { id: $route.params.id } }"
                        ><div class="font-w1" style="color: #132c8c">
                            View report &#62;
                        </div></router-link
                    >
                </div>
            </div>

            <!-- last payment & next payment -->
            <div class="w-90 center mt5 flex-l mb5">
                <div class="w-50-l w-100 mr3-l box-border mb4 mb0-l pa3">
                    <div class="box-border-bottom flex justify-between pb3 pt2">
                        <div class="font-w2">Last payments</div>
                        <a href="#" class="font-w2" style="color: #132c8c">See details</a>
                    </div>
                    <div class="flex justify-between pv3">
                        <div class="font-w1" style="color: #55596ebf">Payments date</div>
                        <div class="font-w1">{{ formatDate(lastPayment?.transactionDate) }}</div>
                    </div>
                    <div class="flex justify-between pb3">
                        <div class="font-w1" style="color: #55596ebf">Type of plan</div>
                        <div class="font-w1">{{ lastPayment?.businessPlan }}</div>
                    </div>
                    <div class="flex justify-between pb3">
                        <div class="font-w1" style="color: #55596ebf">Payments card</div>
                        <div class="font-w1"><span class="b" style="color: #132c8c">VISA</span> 1232 **** ****</div>
                    </div>
                    <div class="flex justify-between pb3">
                        <div class="font-w1" style="color: #55596ebf">Total</div>
                        <div class="font-w1">{{ lastPayment?.amount }}</div>
                    </div>
                    <div class="flex justify-between pt3">
                        <div></div>
                        <div class="flex" v-if="$store.state.Admin.adminUserData.userType === 'admin'">
                            <button class="btn2 mr3" @click="() => toggleChangePlan(true)">Change plan</button>
                            <button class="btn2" @click.prevent="deactivatePlan()">Deactive plan</button>
                        </div>
                    </div>
                </div>
                <div class="w-50-l w-100 ml2-l box-border pa3">
                    <div class="box-border-bottom flex justify-between pb3 pt2">
                        <div class="font-w2">Next payment</div>
                        <div></div>
                    </div>

                    <div class="flex items-center pt4 pb3">
                        <img :src="require('@/assets/images/next-pay.svg')" alt="" />
                        <div class="pl3">
                            <div class="font-w2 pb2" style="color: #55596ebf">Due Date</div>
                            <div class="font-w2">{{ formatDate(lastPayment?.expiryDate) }}</div>
                        </div>
                    </div>

                    <div class="flex justify-between pt5" v-if="$store.state.Admin.adminUserData.userType === 'admin'">
                        <div></div>
                        <button class="btn2">Manage plan</button>
                    </div>
                </div>
            </div>
        </template>
    </AdminWrapper>
</template>

<script>
import { computed, onMounted, ref } from '@vue/runtime-core'
import AdminWrapper from '../../../layout/AdminWrapper.vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { formatDate, formatAmount } from '@/utils/lib'
import Spinner from '@/components/Spinner'
import Swal from 'sweetalert2'
import ChangePlan from '@/components/changePlan'
export default {
    components: {
        AdminWrapper,
        Spinner,
        ChangePlan
    },
    setup() {
        const accountDetails = computed(() => store.state.Admin.accountDetails)
        const lastPayment = computed(() => store.state.Admin.accountDetailsLastPaymentDate)
        const store = useStore()
        const route = useRouter()
        const deactivated = ref(false)
        const getProducts = ref({})

        const deactivateAndReactivateBiz = () => {
            if (accountDetails.value.isDeactivated !== true) {
                Swal.fire({
                    title: `Are you sure you want to deactivate this business?`,
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Yes, Continue',
                    denyButtonText: `No, Cancel`
                }).then(result => {
                    if (result.isConfirmed) {
                        store.dispatch('Admin/deActivateBusiness', route.currentRoute.value.params.id)
                    }
                })
            } else {
                Swal.fire({
                    title: `Are you sure you want to reactivate this business?`,
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Yes, Continue',
                    denyButtonText: `No, Cancel`
                }).then(result => {
                    if (result.isConfirmed) {
                        store.dispatch('Admin/reActivateBusiness', route.currentRoute.value.params.id)
                    }
                })
            }
        }

        const cloneBusiness = () => {
            Swal.fire({
                title: `Are you sure you want to clone this business?`,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Yes, Continue',
                denyButtonText: `No, Cancel`
            }).then(result => {
                if (result.isConfirmed) {
                    store.dispatch('Admin/cloneBusiness', route.currentRoute.value.params.id)

                    window.open('/', '_blank')
                }
            })
        }

        const deactivatePlan = () => {
            Swal.fire({
                title: `Are you sure you want to deactivate the business plan?`,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Yes, Continue',
                denyButtonText: `No, Cancel`
            }).then(result => {
                if (result.isConfirmed) {
                    store.dispatch('Admin/deactivateBizPlan', {
                        id: route.currentRoute.value.params.id,
                        planId: lastPayment.value._id
                    })
                }
            })
        }

        const toggleChangePlan = payload => {
            store.dispatch('Admin/toggleChangePlan', payload)
        }

        const sendTestInvoiceReminder = () => {
            store.dispatch('Admin/sendInvoiceReminder', route.currentRoute.value.params.id)
        }

        const sendTestPnlReport = () => {
            store.dispatch('Admin/sendPnlReport', route.currentRoute.value.params.id)
        }

        const sendWeeklyReport = () => {
            store.dispatch('Admin/sendWeeklyReport', route.currentRoute.value.params.id)
        }

        onMounted(() => {
            store.dispatch('Admin/getAccountBusinessDetails', route.currentRoute.value.params.id)
            store.dispatch('Admin/getBusinessLastPaymentDate', route.currentRoute.value.params.id)
            store.dispatch('Admin/getBusinessLastPaymentDate', route.currentRoute.value.params.id)
            store.dispatch('Admin/fetchBusinessInvoice', route.currentRoute.value.params.id)
            store.dispatch('Admin/fetchBusinessProduct', route.currentRoute.value.params.id)
            store.dispatch('Admin/fetchBusinessEntities', route.currentRoute.value.params.id)
            store.dispatch('Admin/fetchBusinessServices', route.currentRoute.value.params.id)
            store.dispatch('Admin/fetchBusinessTransaction', route.currentRoute.value.params.id)
        })

        return {
            accountDetails,
            lastPayment,
            formatDate,
            formatAmount,
            deactivateAndReactivateBiz,
            deactivated,
            cloneBusiness,
            deactivatePlan,
            toggleChangePlan,
            getProducts,
            sendTestInvoiceReminder,
            sendTestPnlReport,
            sendWeeklyReport,
        }
    }
}
</script>

<style></style>
