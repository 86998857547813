<template>
    <!-- form modal -->

    <div
        v-if="isChangePlan"
        tabindex="0"
        @keydown.esc="closeModal"
        class="w-100 center pt0-l pt7"
        id="entity-form"
        :class="isChangePlan && 'jquery-modal blocker current'"
        @click="handleOnClickOutsideModal"
    >
        <div class="w-50-l w-50 pv2 center box-border" style="background: white; max-width:30rem" ref="createEntityRef">
            <div class="flex justify-between pa3 box-border-bottom pv0">
                <div class="f3 pa3 b">Change Business Plan</div>
                <div>
                    <router-link to="#">
                        <img src="../assets/images/Close-icon.svg" alt="" @click.prevent="closeModal" />
                    </router-link>
                </div>
            </div>

            <!--      Previously div tag-->
            <form @submit.prevent="submitChangePlan()" class="entity-form flexx justify-center" ref="formRef">
                <div class="pa3">
                    <!-- full name -->
                    <div class="">
                        <div class="w-100-l w-100 pr2-l pb0-l pb3">
                            <div class="pv2 font-w1">
                                <label>Choose Plan<span class="required">*</span></label>
                            </div>
                            <select required v-model="planId" class="w-100">
                                <option value="" selected disabled>Select</option>
                                <option value="1">Micro Business Plan</option>
                                <option value="2">Small Business Plan</option>
                                <option value="3">Medium Enterprise Plan</option>
                                <option value="4">Basic Plan</option>
                                <option value="5">Store Plan</option>
                                <option value="6">Professional Plan</option>
                                <option value="7">Enterprise Plan</option>
                            </select>
                        </div>
                        <div v-if="planId !== '' && planId !== '1'" class="w-100-l w-100 pr2-l pb0-l pb3">
                            <div class="pv2 font-w1">
                                <label for="additionalUsers">Additional users</label>
                            </div>
                            <input type="number" min="0" id="additionalUsers" v-model="additionalUsers" />
                        </div>
                        <div v-if="planId !== '' && planId !== '1' && planId !== '3'" class="w-100-l w-100 pr2-l pb0-l pb3">
                            <div class="pv2 font-w1">
                                <label for="additionalBusiness">Additional businesses</label>
                            </div>
                            <input type="number" min="0" id="additionalBusiness" v-model="additionalBusiness" />
                        </div>
                        <div class="w-100 pr2-l pb0-l pb3">
                            <div class="pv2 font-w1">
                                <label>Date<span class="required">*</span></label>
                            </div>
                            <input type="date" v-model="date" required />
                        </div>
                    </div>

                    <div class="flex flex-wrap justify-center" style="gap: 1rem">
                        <button class="mt3 w-100" type="submit">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { formatDateFilter } from '@/utils/lib'
export default {
    name: 'Change Plan',
    props: {
        accountID: String,
        closeModal: Function
    },
    setup(props) {
        const store = useStore()
        const changePlanRef = ref(null)
        const planId = ref('')
        const date = ref('')
        const additionalUsers = ref(0)
        const additionalBusiness = ref(0)

        const isChangePlan = computed(() => store?.state.Admin.showChangePlan)

        watch(() => {
            document.body.style.overflow = isChangePlan.value ? 'hidden' : 'auto'
        })

        watch(
            () => additionalUsers.value,
            (curr, prev) => {
                if (curr !== prev) { 
                    if (curr === '') {
                        additionalUsers.value = 0
                    } else {
                        additionalUsers.value = parseInt(curr)
                    }
                }
            }
        )

        watch(
            () => additionalBusiness.value,
            (curr, prev) => {
                if (curr !== prev) { 
                    if (curr === '') {
                        additionalBusiness.value = 0
                    } else {
                        additionalBusiness.value = parseInt(curr)
                    }
                }
            }
        )

        watch(
            () => planId.value,
            (curr, prev) => {
                if (curr !== prev) { 
                    additionalUsers.value = 0
                    additionalBusiness.value = 0
                }
            }
        )

        const closeChangePlanModal = () => {
            store.dispatch('Admin/toggleChangePlan', false)
            const body = document.querySelector('body')
            body.style.overflow = 'auto'
        }

        const handleOnClickOutsideModal = e => {
            if (changePlanRef?.value && !changePlanRef?.value.contains(e.target)) {
                closeChangePlanModal()
            }
        }

        const submitChangePlan = () => {
            store
                .dispatch('Admin/changeBizPlan', {
                    id: props.accountID,
                    payload: {
                        planId: planId.value,
                        additionalUsers: additionalUsers.value,
                        additionalBusiness: additionalBusiness.value,
                        expiryDate: formatDateFilter(date.value)
                    }
                })
                .then(resp => {
                    // console.log(resp)
                    if (resp?.status === true) {
                        planId.value = ''
                        date.value = ''

                        closeChangePlanModal()
                    }
                })
        }

        return {
            isChangePlan,
            changePlanRef,
            handleOnClickOutsideModal,
            closeChangePlanModal,
            submitChangePlan,
            planId,
            date,
            additionalUsers,
            additionalBusiness,
        }
    }
}
</script>
